.tab-content {
  position: relative;
}

.tabpanel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.tabpanel.opacity-100 {
  opacity: 100;
  visibility: visible;
}
