/* LatestPosts.css */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
.latest-posts {
  padding: 2rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.latest-posts h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.latest-posts .post-card {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.latest-posts .post-card:hover {
  transform: translateY(-5px);
}

.latest-posts .post-card img {
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  object-fit: cover;
  width: 100%;
  height: 12rem;
}

.latest-posts .post-card h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.latest-posts .post-card p {
  color: #4a5568;
  margin-bottom: 1rem;
}

.latest-posts .post-card a {
  color: #3182ce;
  text-decoration: none;
  font-weight: bold;
}

.latest-posts .post-card a:hover {
  text-decoration: underline;
}

.post-card {
  height: 400px; /* Ensure all cards have the same height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.post-image {
  height: 200px; /* Ensure the images have the same height */
}

.slick-slide > div {
  display: flex;
  justify-content: center;
}

.post-card:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
