.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 162, 255, 0.6)),
    url(./b.jpeg);
  background-size: cover;
  background-position: center center;
  height: 60vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
