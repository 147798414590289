@import url("https://fonts.googleapis.com/css?family=Montserrat");
@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: Montserrat !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 255, 255);
  color: rgb(44, 44, 44);
}
