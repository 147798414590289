/* Footer.css */
.footer {
  background-color: #1a202c; /* Darker grey */
  color: white;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  width: 100%;
}

.footer-item {
  text-align: left;
}

.footer-title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #60a5fa; /* Light blue */
  text-align: left;
}

.footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-list li {
  margin-bottom: 0.75rem;
  display: flex;
}

.footer-icon {
  margin-right: 0.5rem;
  color: #60a5fa; /* Light blue */
}

.footer-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #60a5fa; /* Light blue */
}

.footer-text {
  display: inline;
}

.footer-copy {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  color: #9ca3af; /* Light grey */
}

@media (min-width: 768px) {
  .footer-grid {
    grid-template-columns: repeat(2, 1fr);
    align-items: start;
    text-align: left;
  }

  .footer-item:last-child {
    text-align: right;
  }
}
